var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'audience',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v(_vm._s(this.block.block_title))])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"audience_block"},[_c('ul',{staticClass:"audience_list row justify-content-center"},_vm._l((_vm.block.items),function(item,index){return _c('li',{key:index,staticClass:"col-12 col-md-6 col-lg-4"},[_c('div',{staticClass:"audience-item"},[('demographics_percent' in item)?_c('div',{staticClass:"audience-item_top"},[_c('div',{staticClass:"percentage"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.demographics_percent.ru)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"diagramm"},[_c('div',{style:('padding-top: ' + item.demographics_percent.ru + '%')})])]):_vm._e(),_vm._v(" "),('demographics_name' in item)?_c('div',{staticClass:"audience-item_bottom"},[_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.demographics_name.ru)+"\n\t\t\t\t\t\t\t")])]):_vm._e()])])}),0)]),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_c('dynamic-button',{attrs:{"button":_vm.block.button,"block-id":_vm.block.id,"scenario":_vm.block.scenario}})],1),_vm._v(" "),_vm._m(0)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{staticClass:"panel_footer_info"})])
}]

export { render, staticRenderFns }