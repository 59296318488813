<template>
	<div
		:class="['panel', 'audience',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2>{{ this.block.block_title }}</h2>
					</div>
				</div>
				<!--<nuxt-link class="panel_control" to="#"></nuxt-link> item.listing_cover_image_url_default -->
			</div>
			<div class="audience_block">
				<ul class="audience_list row justify-content-center">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-4"
					>
						<div class="audience-item">
							<div v-if="'demographics_percent' in item" class="audience-item_top">
								<div class="percentage">
									{{ item.demographics_percent.ru }}
								</div>
								<div class="diagramm">
									<div :style="'padding-top: ' + item.demographics_percent.ru + '%'" />
								</div>
							</div>
							<div v-if="'demographics_name' in item" class="audience-item_bottom">
								<div class="title">
									{{ item.demographics_name.ru }}
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12 col-sm-8">
						<dynamic-button
							:button="block.button"
							:block-id="block.id"
							:scenario="block.scenario"
						/>
					</div>
					<div class="col-12 col-sm-4">
						<div class="panel_footer_info" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'ProgAudience',
	components: {},
	directives: {},
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/prog-audience";
</style>
